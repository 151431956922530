/* ========================
Navbar
======================== */

.admin_nav{
    background-color: white;
}
nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 60px;  
    position: fixed;   
    top: 0;
    width: 100%;
    z-index: 2;
}

#navbar {
    display: flex;
    align-items: center;
    justify-content: center;
}

#navbar li {
    list-style: none;
    padding: 10px 25px 0px 0px;
    position: relative;
}

#navbar li a {
    text-decoration: none;
    font-size: 18px;
    font-weight: 2px;
    color: black;
    transition: 0.3s ease-in-out;
}

#navbar li :hover,
#navbar li .active {
    color: blue
}


#mobile {
    display: none;
}

#mobile i {
    color: black;
    align-items: center;
}

.img_size {
    height: 100%;
    width: 100%;
}

.nav-cart-count {
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -35px;
    margin-left: 15px;
    border-radius: 15px;
    font-size: 12px;
    background-color: red;
    color: white;
}

.nav-cart-count:hover {
    background-color: white;
    color: white;
}

@media screen and (max-width:769px) {

    #navbar {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: fixed;
        top: 55px;
        left: -250px;
        width: 250px;
        height: 100vh;
        /* background: #ffa31a; */
        background-color: white;
        box-shadow: 0 40px 60px rgba(0, 0, 0, 0.1);
        padding: 40px 0 0 10px;
        transition: 0.3s ease-in-out;
        z-index: 1;
    }

    #navbar.active {
        left: 0px;
    }

    #navbar li {
        margin-bottom: 20px;
    }

    #mobile {
        display: block;
    }

    #mobile i {
        font-size: 24px;
        cursor: pointer;
    }

    .img_size {
        height: 100%;
        width: 100%;
    }

}





/* ============================================ */
/* Admin login form css  */
/* ============================================ */

.form2 {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: #ffffff;
    padding: 30px;
    margin: auto;
    box-shadow: 10px 10px 20px rgba(30, 30, 30, 0.2);
    width: 450px;
    border-radius: 20px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

::placeholder {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}



.flex-column>label {
    color: #151717;
    font-weight: 600;
}

.inputForm {
    border: 1.5px solid #ecedec;
    border-radius: 10px;
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    transition: 0.2s ease-in-out;
}

.input {
    margin-left: 10px;
    border-radius: 10px;
    border: none;
    width: 85%;
    height: 100%;
}

.input:focus {
    outline: none;
}

.inputForm:focus-within {
    border: 1.5px solid #2d79f3;
}

.flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
}

.flex-row>div>label {
    font-size: 14px;
    color: black;
    font-weight: 400;
}

.span {
    font-size: 14px;
    margin-left: 5px;
    color: #2d79f3;
    font-weight: 500;
    cursor: pointer;
}

.p {
    text-align: center;
    color: black;
    font-size: 14px;
    margin: 5px 0;
}