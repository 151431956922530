summary {
    font-size: 1.25rem;
    font-weight: 600;
    background-color: white;
    color: #333;
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    border-radius: 0.25rem;
    text-align: left;
    cursor: pointer;
    position: relative;
  }
  
  details > summary::after {
    position: absolute;
    content: "+";
    right: 10px;
    
  }
  details[open] > summary::after {
    position: absolute;
    content: "-";
    right: 10px;
    
  }
  details > summary::-webkit-details-marker {
    display: none;
  }
  .faq__content{
    margin-top: -20px;
    padding: 0px 20px 0px 30px;
    color: gray;
  }


  @media (max-width: 576px) {

    summary {
        font-size: 15px;
        font-weight: 600;
        background-color: white;
        color: #333;
        padding: 1rem;
        margin-bottom: 1rem;
        outline: none;
        border-radius: 0.25rem;
        text-align: left;
        cursor: pointer;
        position: relative;
      }
    

      .faq__content, .faq_ul{
        font-size: 14px !important;
        color: gray;
      }


  }