/* Allcourse.css */

@import url('https://fonts.googleapis.com/css2?family=Mona+Sans:ital,wght@0,200..900;1,200..900&family=Roboto:ital,wght@0,100..900;1,100..900&family=Stoke&family=Tiro+Kannada&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');

:root {
    --font-primary: "Roboto", sans-serif;
}


.courseheading {
    font-family: var(--font-primary);
}

body {
    margin: 0;
    font-family: var(--font-primary);
}


.Course_main_div {
    padding: 0 !important;
    margin: 0 !important;
}

.coursebtn {
    font-family: var(--font-primary);
    font-weight: 400 !important;
    font-size: 13px !important;
    margin: 0;
    padding: 0px;
    padding-top: 9.2px;
    color: white;
    border: 0;
}

.coursebtn:hover {
    color: rgb(208, 208, 208);
}

.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 300px;
    height: 100%;
    background: #ffffff;
    transform: translateX(100%);
    transition: transform 10s ease ease-in;
    z-index: 999;

}

.sidebar.toggled {
    transform: translateX(0);
}


.sidebar ul {
    list-style-type: none;
    padding: 0;

}


.sidebar ul li:hover {
    padding: 15px 20px;
    color: #ff7900;
    background-color: #ffefe1;
}

.sidebar ul li {
    padding: 15px 20px;
    cursor: pointer;

}



/* All Course css here 
 */

.courselist {
    overflow-y: scroll;
    height: 480px;
    font-family: var(--font-primary);
}

.backbtn {
    color: #ff7900;

}

.backbtn:hover {
    color: #ff7900;
}


/* Small devices (phones, 576px and up)-------------------- */

@media only screen and (max-width: 600px) {
    .coursebtn {
        position: absolute;
        z-index: 999;
        margin-left: 60%;
    }

}

/* Medium devices (tablets, 768px and up)---------------- */
@media (max-width: 1024px) and (min-width: 768px) {
    .coursebtn {
        position: absolute;
        z-index: 500;
        bottom: -0%;
        max-width: 150px;
        margin-left: 505%;
    }

}





/* show content css    */
.accordion {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    font-family: var(--font-primary);
}

.accordion ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.accordion-header {
    border: 0;
    padding: 10px;
    padding-bottom: 0;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.accordion-header:hover {
    background: transparent;
    color: #ff7900;
}

.accordion-content {
    display: none;
    padding: 0;

    /* border-top: 0px solid #ddd; */
}

.accordion-content li:hover {
    color: #ff7900;
    /* border-top: 0px solid #ddd; */
}

.accordion-content.active {
    display: block;
}