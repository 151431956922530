.main_slider_div {
  position: relative;
  height: 80vh;
  overflow: hidden;
}

.each-slide-effect {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 650px;
  position: relative;
  /* z-index: -1; */
}


.each-slide-effect img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  background-position: center;
  background-size: cover;
  top: 0;
  left: 0;
  z-index: 0;
}


.contentdiv {
  width: 65%;
  z-index: 10;
  margin: auto;
  margin-top: -520px;
  margin-bottom: 300px;
}

.Slid_contenttext {
  width: 100%;
  text-align: center;
  z-index: 0;
  margin: auto;
  color: white;
  font-family: "Roboto", sans-serif;
  font-weight: 400;

}

.Slid_contenttext p {
  font-weight: bold;
  font-size: 38px !important;
  font-family: "Roboto", sans-serif;

}



/* css for form input  */

.form-inline {
  border: none;
  padding: 0;
}

/* Input field styles */
input.nosubmitcls {
  padding-left: 35px !important;
  background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 10px center;
}


.searchbtn {
  border: none;
  border-radius: 0 !important;
  width: 120px;
}



.counselling_btn {
  font-size: 0.8rem;
  color: white;
  float: right !important;
  font-size: 0.9rem;
  align-items: center;
  height: 40px;
  border-radius: 0.3rem;
  font-weight: 600;
  padding: 10px 10px 10px 10px;
  border: none;
  cursor: pointer;
  box-shadow: 0 0.5rem 1rem rgba(143, 142, 142, 0.15) !important;
  background: var(--btn-color);
}

.counselling_btn:hover {
  background: #f78119;
  text-decoration: none;
  color: white;
}

.Recent_Visits p {
  font-size: 20px !important;

}

.Recent_visit_btn {
  font-weight: bold;
  border: 2px solid wheat;
  border-radius: 5px;
  margin-left: 10px;
  color: white;
  padding: 5px 16px;
  font-size: 13px;
  transition: all 0.3s ease-in-out;
}

.Recent_visit_btn:hover {
  background-color: rgba(255, 255, 255, 0.135);
  color: #ffff;
  text-decoration: none;
}

/* Media query for header slider form */
/* small device ------------------ */
@media only screen and (max-width: 600px) {
  .main_slider_div {
    position: relative;
    height: 70vh;
    overflow: hidden;
  }

  .each-slide-effect {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 350px;
    position: relative;
    /* z-index: -1; */
  }

  .contentdiv {
    display: none;
  }
}

/* medium device ------------------------ */
@media (max-width: 1024px) and (min-width: 768px) {
  .contentdiv {
    display: none;
  }

}




/* popup box css here  */


.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999 !important;
}

.popup-content {
  background: white;
  top: 0;
  margin: 0 0 0 0;
  padding: 25px 0 25px 0;
  border-radius: 5px;
  position: relative;
  width: 80%;
  height: 75vh;
  max-width: 100%;
  z-index: 9999 !important;
}

.close-btn {
  position: absolute;
  top: 20px;
  right: 25px;
  font-size: 25px;
  font-weight: bold;
  cursor: pointer;
  background: none;
  border: none;
  z-index: 100;
}




.Form_container {
  z-index: 1000 !important;
  padding: 10px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: rgba(209, 209, 209, 0.24) 0px 3px 8px;
}




.text_cont {
  display: flex;
  align-items: center;
  gap: 30px;
  padding: 0 0 0.5rem 0;
  margin: 0 0 0.5rem 0;

}




/* Responsive css  */
/* Small devices (phones, 576px and up)-------------------- */
@media only screen and (max-width: 600px) {
  .popup-content {
    background: white;
    top: 0;
    margin: 0 0 0 0;
    border-radius: 5px;
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: scroll;
    max-width: 100%;
    z-index: 9999 !important;
  }

  .close-btn {
    font-size: 20px;
    top: 5px;
    right: 5px;
  }

  .text_cont {
    gap: 8px;
    padding-bottom: 0.3rem;
    margin-bottom: 0.3rem;
  }
}


/* slogan div css  */
.slogan_div {
  position: relative;
  top: 50%;
  left: 50%;
  margin-top: 5rem !important;
  padding-top: 5rem !important;
  transform: translate(-80%, -50%);
  /* Centers the div */
  width: auto;
  /* Adjust width dynamically */
  z-index: 1;
  /* Make it visible */
  text-align: center;
}

.slogan-strip {
  background-color: #ff7900;
  padding: 5px 15px;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 25px;
  border-radius: 5px;
  display: inline-block;
}

.slogon_div .solgentext p {
  margin: 0;
  font-size: 25px !important;
}

/* .slogan_div.hidden {
  display: none;
} */